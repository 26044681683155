import { Injectable } from '@angular/core';
import { PolicyGroupConstant } from '../../constants/policy-group-constant';
import { PolicyGroupPermissionConstant } from '../../constants/policy-group-permission-constant';
import { UserPolicy } from '../../models/UserPolicy';
import { UserAuthorizationService } from '../user-authorization.service';
import { ProjectMember } from '../../models/project-member';
import { UserAccessPolicyRequest } from '../../models/user-access-policy-request';

@Injectable({
  providedIn: 'root',
})
export class UserPolicyAuthorizationService {
  public userPolicy: UserPolicy;
  private projectMember: ProjectMember;
  private mfReadOnlyPolicyRequest: UserAccessPolicyRequest;
  private mfAdminOnlyPolicyRequest: UserAccessPolicyRequest;
  private dcAdminOnlyPolicyRequest: UserAccessPolicyRequest;
  private dcReadOnlyPolicyRequest: UserAccessPolicyRequest;

  constructor(private userAuthorizationService: UserAuthorizationService) {
    this.mfReadOnlyPolicyRequest = new UserAccessPolicyRequest();
    this.mfAdminOnlyPolicyRequest = new UserAccessPolicyRequest();
    this.dcAdminOnlyPolicyRequest = new UserAccessPolicyRequest();
    this.dcReadOnlyPolicyRequest = new UserAccessPolicyRequest();
  }

  private getMFReadOnlyAccess(): Promise<boolean> {
    this.mfReadOnlyPolicyRequest.resource =
      PolicyGroupConstant.MFReadOnlyAccess;
    this.mfReadOnlyPolicyRequest.permission =
      PolicyGroupPermissionConstant.Read;
    return this.userAuthorizationService.getUserAuthorization(
      this.mfReadOnlyPolicyRequest
    );
  }

  private getMFAdminAccess(): Promise<boolean> {
    this.mfAdminOnlyPolicyRequest.resource = PolicyGroupConstant.MFAdminAccess;
    this.mfAdminOnlyPolicyRequest.permission =
      PolicyGroupPermissionConstant.Owner;
    return this.userAuthorizationService.getUserAuthorization(
      this.mfAdminOnlyPolicyRequest
    );
  }

  private getDCReadOnlyAccess(): Promise<boolean> {
    this.dcReadOnlyPolicyRequest.resource =
      PolicyGroupConstant.DCReadOnlyAccess;
    this.dcReadOnlyPolicyRequest.permission =
      PolicyGroupPermissionConstant.Read;
    return this.userAuthorizationService.getUserAuthorization(
      this.dcReadOnlyPolicyRequest
    );
  }

  private getDCAdminAccess(): Promise<boolean> {
    this.dcAdminOnlyPolicyRequest.resource = PolicyGroupConstant.DCAdminAccess;
    this.dcAdminOnlyPolicyRequest.permission =
      PolicyGroupPermissionConstant.Owner;
    return this.userAuthorizationService.getUserAuthorization(
      this.dcAdminOnlyPolicyRequest
    );
  }

  public get userPolicyRoles(): UserPolicy {
    return this.userPolicy;
  }

  public async getUserPolicy(): Promise<boolean> {
    if (this.userPolicy == null || Object.keys(this.userPolicy).length === 0) {
      this.userPolicy = {
        hasDCAdminAccess: false,
        hasDCReadOnlyAccess: false,
        hasMFAdminAccess: false,
        hasMFReadOnlyAccess: false,
        hasProjectMemberAccess: false,
        hasLeadMFAccess: false,
        hasPrimeMFAccess: false,
        hasGlobalQRMAdminAccess: false,
        hasSaveDraftAndDCFinalizeAccess: false,
        hasERFinalizeAccess : false,
        hasERRatingAccess: false,
        hasGenerateFullAndLimitedERSummaryAccess: false,
        hasReFinalizeAccess: false,
        hasEditPreviousObservationAccess: false,
        hasEditPreviousObservationAccessIntegrated: false,
        hasEditPreviousObservationAccessLEP: false,
        hasGeneratedLimitedExportAccess: false,
        hasEmailNotificationAccess: false,
        hasIntegratedSaveGenerateLimitedAndFullERSummaryAccess : false,
      hasIntegratedFinalizeERandDCERAccess : false,
      hasIntegratedERRatingAndCommentAccess: false,
      hasIntegratedDCCategoriesAndDCComponentAndRatingAndCommentAccess: false,
      hasDCSaveAsDraftAccess:false

      };
      [
        this.userPolicy.hasDCAdminAccess,
        this.userPolicy.hasDCReadOnlyAccess,
        this.userPolicy.hasMFAdminAccess,
        this.userPolicy.hasMFReadOnlyAccess,
        this.projectMember,
      ] = await Promise.all([
        this.getDCAdminAccess(),
        this.getDCReadOnlyAccess(),
        this.getMFAdminAccess(),
        this.getMFReadOnlyAccess(),
        this.userAuthorizationService.getAuthorizeByProjectWithMembers(
          Number(sessionStorage.getItem('projectId'))
        ),
      ]);
      this.userPolicy.hasProjectMemberAccess =
        this.projectMember.hasProjectMemberAccess;
      this.userPolicy.hasLeadMFAccess = this.projectMember.hasLeadMFAccess;
      this.userPolicy.hasPrimeMFAccess = this.projectMember.hasPrimeMFAccess;
      this.userPolicy.hasGlobalQRMAdminAccess = this.projectMember.hasGlobalQRMAdminAccess;
    }
    const result =
      this.userPolicy.hasDCAdminAccess ||
      this.userPolicy.hasDCReadOnlyAccess ||
      this.userPolicy.hasMFAdminAccess ||
      this.userPolicy.hasMFReadOnlyAccess;
    return result || this.userPolicy.hasProjectMemberAccess;
  }
}
