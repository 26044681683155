<header class="header">
  <kendo-appbar position="top"
                themeColor="dark"
                class="header__wrap container">
    <kendo-appbar-section>
      <button class="header__btn-logo" (click)="navigateToUrl();">
        <img src="assets/images/core/logo.svg"
             alt="Deloitte logo"
             class="header__logo">
      </button>
    </kendo-appbar-section>

    <kendo-appbar-section>
      <div class="header__project-name">
        Delivery Excellence Plan
      </div>
    </kendo-appbar-section>

    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      <kendo-dropdownlist [data]="userRoleView?.userView"
                          textField="displayText"
                          valueField="referenceId"
                          size="large"
                          fillMode="outline"
                          class="header__select"
                          aria-label="select view"
                          [(ngModel)]="selectedUserRoleView"
                          [popupSettings]="{
                            popupClass: 'header__select-popup',
                            animate: false
                          }"
                          (valueChange)="userRoleViewChange($event)"></kendo-dropdownlist>

      <button kendoButton
              fillMode="flat"
              size="large"
              themeColor="dark"
              aria-label="messages"
              (click)="sendMail()">
        <span class="icon icon_email__s__stroke"></span>
      </button>

      <kendo-button fillMode="flat"
                    class="header__user-btn"
                    (click)="toggle()"
                    #userBtn>
        <kendo-avatar
                      [imageSrc]=""
                      [initials]="initials"
                      rounded="none"
                      fillMode="none"
                      width="40px"
                      height="40px"></kendo-avatar>
      </kendo-button>

      <kendo-popup *ngIf="showUserMenu"
                   [anchor]="userBtn.element"
                   [animate]="false"
                   popupClass="user-menu"
                   #userMenu>
        <div class="user-menu-profile">
          <kendo-avatar [imageSrc]=""
                        [initials]="initials"
                        rounded="none"
                        fillMode="none"
                        width="32px"
                        height="32px"
                        class="user-menu-profile__avatar"></kendo-avatar>

          <div class="user-menu-profile__info">
            <strong *ngIf="userFullName"
                    class="user-menu-profile__name">{{userFullName}}</strong>
          </div>
        </div>
      </kendo-popup>

    </kendo-appbar-section>
  </kendo-appbar>
</header>
