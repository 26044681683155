export const environment ={
  apiBase : 'https://stagedepngapi.deloitteresources.com/',
  backgroundUrl:'https://stagedep.deloitteresources.com/ProjectDetail/ProjectDetail',
  engagementReviewUrl:'https://stagedep.deloitteresources.com/PQMP/EngagementReviews',
  dcEngagementReviewUrl: 'https://stagedep.deloitteresources.com/PQMP/USIEngagementReviews',
  homeUrl:'https://stagedep.deloitteresources.com/Admin/Index',
  periodicUpdatesUrl:'https://stagedep.deloitteresources.com/MonthlyUpdates/MonthlyUpdates',
  portfolioUrl:'https://stagedep.deloitteresources.com/PM/PortfolioManagement',
  printReportQC: 'https://stagedep.deloitteresources.com/QualityCheck/PrintReview',
  printViewUrl:'https://stagedep.deloitteresources.com/PrintView/PrintView',
  proposalReviewUrl:'https://stagedep.deloitteresources.com/Proposal/ProposalReview',
  qualityCheckUrl:'https://stagedep.deloitteresources.com/QualityCheck/QualityCheckReviews',
  reviewBoardUrl:'https://stagedep.deloitteresources.com/DRB/DRBReviews',
  teamSurveyUrl:'https://stagedep.deloitteresources.com/TeamSurvey/ProjectTeamSurvey',
  usiengagementReviewUrl:'https://stagedep.deloitteresources.com/PQMP/USIEngagementReviews',
  depBaseURL:'https://stagedep.deloitteresources.com/',

  argusAD : {
    clientId : '1ba856ac-e37a-4584-97e8-02f7d4435fd1',
    authority : 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
    redirectUri : 'https://stagedepng.deloitteresources.com',
    postLogoutRedirectUri : 'https://stagedepng.deloitteresources.com',
    navigateToLoginRequestUrl : true,
    protectedResourceMapURL : 'https://graph.microsoft.com/v1.0/me',
    protectedResourceScopes : 'user.read',
    apiScopes : 'https://stagedepng.deloitteresources.com/api/user_impersonation',
    protectedResourceEndpoint : 'https://stagedepngapi.deloitteresources.com'
  }
}
