import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { UserManagementService } from './shared/services/usermanagement.service';
import { LoaderService } from './shared/services/loader.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _destroying$ = new Subject<void>();

  title = 'demo-app';
  public userName!: string;
  public selectedViewIndex: string;
  public loadingPanelVisible = true;
  constructor(
    private usermanagementService: UserManagementService,
    private router: Router,
    private loaderService: LoaderService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    console.log('new deployment version 1.0.1');
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const projectId = event.snapshot.params['projectId'];
        if (projectId) {
          sessionStorage.setItem('projectId', projectId);
        }
        this.selectedViewIndex = event.snapshot.params['selectedViewIndex'];
        if (this.selectedViewIndex) {
          sessionStorage.setItem('selectedViewIndex', this.selectedViewIndex);
        }
      }
    });
    this.loaderService.firstLoad.subscribe((firstLoadComplete) => {
      if (firstLoadComplete) {
        //hide the global loader
        this.loadingPanelVisible = false;
        this.getUserName();
      }
    });
  }

  getUserName(): void {
    this.userName = this.usermanagementService.getUserName();
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(({ eventType, interactionType, error }: EventMessage) => {
        console.log(`eventType: ${eventType}`);
        console.log(`interactionType: ${interactionType}`);
        if (error) {
          console.log(`error: ${error}`);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)
      )
      .subscribe(({ eventType, interactionType, error }: EventMessage) => {
        console.log(`eventType: ${eventType}`);
        console.log(`interactionType: ${interactionType}`);
        if (error) {
          console.log(`error: ${error}`);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe(({ eventType, interactionType, error }: EventMessage) => {
        console.log(`eventType: ${eventType}`);
        console.log(`interactionType: ${interactionType}`);
        if (error) {
          console.log(`error: ${error}`);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        )
      )
      .subscribe(({ eventType, interactionType, error }: EventMessage) => {
        console.log(`eventType: ${eventType}`);
        console.log(`interactionType: ${interactionType}`);
        if (error) {
          console.log(`error: ${error}`);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
