export const PolicyGroupConstant = {
  MFReadOnlyAccess: 'ER_2_MF_Review_Access',
  MFAdminAccess: 'ER_2_MF_Admin_Access',
  DCReadOnlyAccess: 'ER_2_DC_Review_Access',
  DCAdminAccess: 'ER_2_DC_Admin_Access',
  TSAndQCInitiateAccess: 'ER_2_TS_AND_QC_Initiate',
  ERReschedule: 'ER_2_ER_Reschedule',
  DCRescheduleAccess: 'ER_2_DC_ER_Reschedule',
  PortfolioManagementTab: 'ER_2_Portfolio_Management_Tab',
  ERPPTAccess: 'Project_EngagementReview_PrintView',
  DCERPPTAccess: 'Project_DCEngagementReview_PrintView',

  ERSaveDraftAccess:
    'ER_2_ER_Save_As_Draft',
    ERFinalizeAccess : 'ER_2_ER_Finalize',
  DCSaveDraftAndFinalizeAndOfflineTemplateAccess:
    'ER_2_DC_ER_Save_As_Draft_Finalize',
  DCSaveDraftAccess:
    'ER_2_DC_ER_Save_As_Draft',
  ERRatingAccess: 'ER_2_ER_Rating_comment',
  GenerateFullAndLimitedERSummaryAccess : 'ER_2_ER_Generate_ER_Summary',
  GenerateFullAndLimitedDCERSummaryAccess : 'ER_2_DC_ER_Generate_ER_Summary',
  ERLimitedExportAccess:'ER_2_ER_Generate_ER_Summary_Export',
  DCERLimitedExportAccess:'ER_2_DC_ER_Generate_ER_Summary_Export',
  ERReFinalizeRequest:'ER_2_ER_RE_Finalize',
  DCReFinalizeRequest:'ER_2_DC_ER_RE_Finalize',
  EREditPreviousObservationAccess:'ER_2_ER_Edit_Previous_Observation',
  DCEditPreviousObservationAccess:'ER_2_DCER_Edit_Previous_Observation',
  IntegratedEditPreviousObservationAccess:'ER_2_Integrated_Edit_Previous_Observation',
  // IntegrationEditPreviousObservationAccess:'IntegrationEditPreviousObservationAccess',
  EREmailNotificationAccess:'ER_2_ER_Email_Notification',
  DCEmailNotificationAccess :'ER_2_DCER_Email_Notification',
  IntegratedSaveGenerateLimitedAndFullERSummaryAccess : 'ER_2_Integrated_SaveAs_Generate_ER_Summary',
  IntegratedFinalizeERandDCERAccess : 'ER_2_Integrated_ER_DCER_Finalize',
  IntegratedDCCategoriesAndDCComponentAndRatingAndCommentAccess : 'IntegratedDCCategoriesAndDCComponentAndRatingAndCommentAccess',
  IntegratedERRatingAndComment : 'ER_2_ER_Rating_comment',
  IntegratedDCERCategoriesCommentsRatingAndComment :'ER_2_DC_Categories_DC_Component_Rating_Comment',
  EREditPreviousObservationAccess_LEP:"ER_2_ER_Edit_Previous_Observation_LEP"
};
