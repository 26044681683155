import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
     auth: {
         clientId : environment.argusAD.clientId,
         authority : environment.argusAD.authority,
         redirectUri : environment.argusAD.redirectUri,
         postLogoutRedirectUri : environment.argusAD.postLogoutRedirectUri,
         navigateToLoginRequestUrl : environment.argusAD.navigateToLoginRequestUrl
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE,
     }
 }
