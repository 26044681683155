import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserPolicyAuthorizationService } from '../../services/userservice/user-policy-authorization.service';
import { ERoutingPaths } from '../../models/ERoutingPaths';

@Injectable({ providedIn: 'root' })
export class UserPolicyRolesGuard {
  constructor(
    private userPolicyAuthorizationService: UserPolicyAuthorizationService,
    private router: Router
  ) {}
  public async canActivate (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean> {
    //return await this.userPolicyAuthorizationService.getUserPolicy();
    const result = await this.userPolicyAuthorizationService.getUserPolicy();
    if(result){
      return result;
    }
    else{
      return this.router.navigate(['/' + ERoutingPaths.accessDenied], {queryParams: {errorType : 'Unauthorized'}}); 
    }
  }
}
