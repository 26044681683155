import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { ERoutingPaths } from './shared/models/ERoutingPaths';
import { UserPolicyRolesGuard } from './shared/guards/user-policy-roles/user-policy-roles.guard';
import { PrintViewComponent } from './pages/print-view/print-view.component';

const routes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: ERoutingPaths.qaDashboard,
  },
  {
    path: ERoutingPaths.healthCheck,
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/health-check/health-check.module').then(
        (m) => m.HealthcheckModule
      ),
  },
  {
    path: ERoutingPaths.qaDashboardWithParams,
    canActivate: [MsalGuard],
    // data: {
    //   policyGroup: PolicyGroupConstant.DCAdminAccess,
    //   permission: PolicyGroupPermissonConstant.Owner,
    // },
    loadChildren: () =>
      import('./pages/qa-dashboard/qa-dashboard.module').then(
        (m) => m.QaDashboardModule
      ),
  },
  {
    path: ERoutingPaths.home,
    // data: {
    //   policyGroup: PolicyGroupConstant.DCAdminAccess,
    //   permission: PolicyGroupPermissonConstant.Owner,
    // },
    redirectTo: ERoutingPaths.qaDashboard,
    pathMatch: 'full',
  },
  {
    path: ERoutingPaths.qaDashboard,
    canActivate: [MsalGuard],
    // data: {
    //   policyGroup: PolicyGroupConstant.DCAdminAccess,
    //   permission: PolicyGroupPermissonConstant.Owner,
    // },
    loadChildren: () =>
      import('./pages/qa-dashboard/qa-dashboard.module').then(
        (m) => m.QaDashboardModule
      ),
  },
  {
    path: ERoutingPaths.engagementReviews,
    canActivate: [MsalGuard],
    // data: {
    //   policyGroup: PolicyGroupConstant.DCAdminAccess,
    //   permission: PolicyGroupPermissonConstant.Owner,
    // },
    loadChildren: () =>
      import('./pages/engagement-reviews/engagement-reviews.module').then(
        (m) => m.EngagementReviewsModule
      ),
  },
  {
    path: ERoutingPaths.erPrintView,
    loadChildren: () =>
      import('./pages/print-view/print-view.module').then(
        (m) => m.PrintViewModule
      ),
  },
  { 
    path: ERoutingPaths.accessDenied,
    loadChildren: () =>
      import('./shared/components/access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      )
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
