import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProjectDetailVM } from '../models/project-details-vm';
import { ProjectTabVisibility } from '../models/ProjectTabVisibility';
import { IErOwnerDetails } from '../models/owner-details';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiBase}api/project`;
  }

  public getProjectDetails(projectId: number): Promise<IProjectDetailVM> {
    return firstValueFrom(
      this.http.get<IProjectDetailVM>(`${this.baseUrl}/Get/${projectId}`)
    );
  }

  public checkErVersion(projectId: number): Promise<boolean> {
    return firstValueFrom(
      this.http.get<boolean>(`${this.baseUrl}/CheckVersion/${projectId}`)
    );
  }
  public getProjectTabVisibility(projectId: number): Promise<ProjectTabVisibility> {
    return firstValueFrom(
      this.http.get<ProjectTabVisibility>(`${this.baseUrl}/GetProjectTabVisibility/${projectId}`)
    );
  }

  public async getProjectMemberDetails(projectId: number): Promise<IErOwnerDetails[]> {
    return firstValueFrom(
      this.http.get<IErOwnerDetails[]>(`${this.baseUrl}/GetProjectMembers/${projectId}`)
    );
  }

}
