import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  public userName! : string;

  constructor(private authService: MsalService){
  }


  getIdToken(): string | null | undefined {
    const account = this.authService.instance.getAllAccounts()[0];
    return account ? account.name : null;
  }

  decodeToken(token: string): any {
    return 'ok';
  }

  getUserName(): any {
    const idToken = this.getIdToken();

    if (idToken) {
      return idToken || null;
    }
  }

  getUserEmailID(): string{
    const account = this.authService.instance.getAllAccounts()[0];
    return account ? account.username : null;
  }
}
