import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { RouterModule } from '@angular/router';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { RatingComponent } from './rating/rating.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { MulticheckFilterComponent } from './multicheck-filter/multicheck-filter.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { ProjectService } from './services/project.service';
import { ProjectManagerService } from './services/managers/project-manager.service';
import { RedirectUrlService } from './services/redirect-url.service';
import { IndicatorsModule, LoaderModule } from '@progress/kendo-angular-indicators';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from "@progress/kendo-angular-notification";

@NgModule({
  declarations: [
    NavigationComponent,
    ProjectDetailsComponent,
    RatingComponent,
    ActionsMenuComponent,
    MulticheckFilterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonsModule,
    TooltipsModule,
    PopupModule,
    LabelModule,
    InputsModule,
    IndicatorsModule,
    GridModule,
    ButtonModule,
    DialogModule,
    TextBoxModule,
    DateInputsModule,
    LoaderModule,
    NotificationModule
  ],
  exports: [
    NavigationComponent,
    ProjectDetailsComponent,
    RatingComponent,
    ActionsMenuComponent,
    MulticheckFilterComponent,
    ButtonsModule,
    TooltipsModule,
    PopupModule,
    LabelModule,
    InputsModule,
    IndicatorsModule,
    GridModule,
    ButtonModule,
    DialogModule,
    TextBoxModule,
    DateInputsModule,
    LoaderModule,
  ],
  providers: [ProjectService, ProjectManagerService, RedirectUrlService],
})
export class SharedModule {}
