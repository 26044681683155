import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const msalService = this.injector.get(MsalService);
    const timestamp = new Date().toISOString();

    // Log the error to the console
    console.error(`CEH-[Error] ${timestamp}`, error);

    // Handle different types of errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error
      console.error('CEH-Server error:', error.message);
    } else {
      // Client-side or unknown error
      console.error('CEH-Client or unknown error:', error.message);
    }

    // Custom logic for MSAL errors
    if (msalService && error.errorCode) {
      console.error('CEH-MSAL error:', error.errorCode);
      console.error('CEH-MSAL error description:', error.errorMessage);
    }
  }
}
