import { Injectable } from '@angular/core';
import { ProjectManagerService } from './managers/project-manager.service';

@Injectable()
export class RedirectUrlService {
  constructor(private projectManagerService: ProjectManagerService) {}
  public redirectUrl(
    url: string,
    reviewType?: string,
    reviewId?: string,
    isHTML?:boolean,
    isLegacyER?:boolean
  ): void {
    if (url != null || url != undefined) {
      const redirect = new URL(url);
      redirect.searchParams.append(
        'projectID',
        this.projectManagerService.projectDetails.projectId.toString()
      );
      redirect.searchParams.append(
        'selectedViewIndex',
        this.projectManagerService.selectedViewIndex.toString()
      );
      if (reviewType != null || reviewType != undefined) {
        redirect.searchParams.append('reviewType', reviewType);
      }
      if (reviewId != null || reviewId != undefined)
      {
        if(url.includes('QualityCheck'))
        {
          redirect.searchParams.append('reviewId', reviewId);
        }
        else if(isLegacyER)
        {
          redirect.searchParams.append('ERreviewID', reviewId);
        }
        else
        {
          redirect.searchParams.append('reviewID', reviewId);
        }
      }

      if(isHTML){
        window.open(redirect, '_blank');
      }else{
        window.location.assign(redirect);
      }

    }
  }
}
