import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from './header/header.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { RouterModule } from '@angular/router';
import { IndicatorsModule } from "@progress/kendo-angular-indicators";


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    ButtonsModule,
    PopupModule,
    DropDownsModule,
    IndicatorsModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class CoreModule { }
