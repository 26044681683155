<app-header [userFullName]="userName" [selectedViewItem]="selectedViewIndex"></app-header>
<router-outlet></router-outlet>
<div class="loading-inner" *ngIf="loadingPanelVisible">
    <div class="k-loading-panel">
      <div class="k-loading-panel-mask"></div>
      <div class="k-loading-panel-wrapper">
        <kendo-loader
          type="infinite-spinner"
          themeColor="primary"
          size="large"
        >
        </kendo-loader>
        <div class="k-loading-panel-text">Loading...</div>
      </div>
    </div>
</div>
