import { Injectable } from '@angular/core';
import { IProjectDetailVM } from '../../models/project-details-vm';
import { ProjectService } from '../project.service';
import { ILabelValue } from '../../models/ILabelValue';
import { ProjectTabVisibility } from '../../models/ProjectTabVisibility';
import { IErOwnerDetails } from '../../models/owner-details';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagerService {
  projectDetails: IProjectDetailVM;
  projectMembersDetails: IErOwnerDetails[] = [];
  tabDetails: ProjectTabVisibility;
  isErEnabled: boolean;

  public erMenuTab = new BehaviorSubject<string>("");
  public erMenuTab$ = this.erMenuTab.asObservable();

  constructor(private projectService: ProjectService) {}

  public async getProjectDetails(projectId: number): Promise<IProjectDetailVM> {
    this.projectDetails = await this.projectService.getProjectDetails(
      projectId
    );
    return this.projectDetails;
  }

  public async getProjectMemberDetail(projectId: number): Promise<IErOwnerDetails[]> {
    this.projectMembersDetails = await this.projectService.getProjectMemberDetails(
      projectId
    );
    return this.projectMembersDetails;
  }

  public get projectId(): number {
    const projectId = sessionStorage.getItem('projectId');
    if (projectId) {
      return Number(projectId);
    }
    return 0;
  }

  public get selectedViewIndex(): number {
    const selectedviewIndex = sessionStorage.getItem('selectedViewIndex');
    if (selectedviewIndex) {
      return Number(selectedviewIndex);
    }
    return 0;
  }

  public mapProjectDetailsToGenericKeyValuePairs(
    projectData: IProjectDetailVM
  ): ILabelValue<string, string>[] {
    let projectDetailsData: ILabelValue<string, string>[] = [
      {
        label: 'DEP ID',
        value: projectData.projectId.toString(),
      },
      {
        label: 'Client',
        value: projectData.clientName,
      },
      {
        label: 'Project name',
        value: projectData.projectName,
      },
      {
        label: 'Lead engagement partner',
        value: projectData.lep,
      },
      {
        label: 'Lead engagement reviewer/QA partner',
        value: projectData.ler,
      },
      {
        label: 'Lead MF',
        value: projectData.leadMF,
      },
      {
        label: 'Offering',
        value: projectData.primaryOffering,
      },
      {
        label: 'Industry',
        value: projectData.industry,
      },
      {
        label: 'QRM lead/Risk manager',
        value: projectData.riskManager,
      },
      {
        label: 'Primary opportunity ID',
        value: projectData.opportunityId,
      },
      {
        label: 'Total value',
        value:  projectData.contractValue === "0" ? "" : projectData.currency === ""? Number(projectData.contractValue).toLocaleString('en-GB') :  projectData.currency +" "+ Number(projectData.contractValue).toLocaleString('en-GB') ,
      },
      {
        label: 'Fee type',
        value: projectData.feeType,
      },
    ];

    if (projectData.isDcEnabled) {
      projectDetailsData.push(
        // {
        //   label: 'Enable DC',
        //   value: 'Yes',
        // },
        {
          label: 'Delivery center involved',
          value: projectData.deliveryCenterName,
        },
        // {
        //   label: 'Enable integrated DC review',
        //   value: 'No',
        // },
        {
          label: 'DC reviewer',
          value: projectData.dcReviewer,
        },
        {
          label: 'Center delivery manager',
          value: projectData.cdm,
        }
      );
    }

    return projectDetailsData;
  }

  public async checkErVersion(projectId: number): Promise<boolean> {
    const result = await this.projectService.checkErVersion(projectId);
    this.isErEnabled = result;
    return result;
  }

  public async getProjectTabVisibility(
    projectId: number
  ): Promise<ProjectTabVisibility> {
    this.tabDetails = await this.projectService.getProjectTabVisibility(
      projectId
    );
    return this.tabDetails;
  }
}
