export const ERoutingPaths = {
  home: '',
  qaDashboardWithParams: 'qa-dashboard/:projectId/:selectedViewIndex',
  qaDashboard: 'qa-dashboard',
  engagementReviews: 'engagement-reviews',
  erPrintView:'print-review/:projectId/:reviewId',
  qaDasboardPrintView:'print-review',
  accessDenied: 'access-denied',
  healthCheck: 'health-check',
};
