import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { UserAccessPolicyRequest } from '../models/user-access-policy-request';
import { ProjectMember } from '../models/project-member';
import { UserRoleViewResponse } from '../models/user-role-view-response';

@Injectable({
  providedIn: 'root',
})
export class UserAuthorizationService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiBase}api/AuthorizeUser`;
  }

  public async getUserAuthorization(
    userAccessPolicyRequest: UserAccessPolicyRequest
  ): Promise<boolean> {
    const url = `${this.baseUrl}/GetAccessPolicy`;
    userAccessPolicyRequest.projectId = Number(sessionStorage.getItem('projectId'));
    return firstValueFrom(
      this.http.post<boolean>(url, userAccessPolicyRequest)
    );
  }

  public async getAuthorizeByProjectWithMembers(
    projectId: number
  ): Promise<ProjectMember> {
    const url = this.baseUrl + `/GetProjectMember/${projectId}`;
    return firstValueFrom(this.http.get<ProjectMember>(url));
  }

  public async getUserRoleView(projectId : number): Promise<UserRoleViewResponse> {
    const url = `${this.baseUrl}/ApplicationRoleView/${projectId}`;
    return firstValueFrom(this.http.get<UserRoleViewResponse>(url));
  }


}
