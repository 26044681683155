import {
  AfterContentChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import {
  UserRoleView,
  UserRoleViewResponse,
} from 'src/app/shared/models/user-role-view-response';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UserAuthorizationService } from 'src/app/shared/services/user-authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  @ViewChild('userBtn', { read: ElementRef }) userBtn: ElementRef;
  @ViewChild('userMenu', { read: ElementRef }) userMenu: ElementRef;
  @Input() userFullName: string;
  @Input() selectedViewItem = '0';
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.toggle(false);
    }
  }
  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  public showUserMenu: boolean;
  public userRoleView: UserRoleViewResponse;
  public selectedUserRoleView: UserRoleView;
  public initials: string;
  private selectedViewIndex = Number(
    sessionStorage.getItem('selectedViewIndex')
  );
  private projectId: string;

  constructor(
    private userAuthorizationService: UserAuthorizationService,
    private loaderService: LoaderService
  ) {}

  public ngAfterContentChecked(): void {
    this.getUserData();
  }

  public async ngOnInit(): Promise<void> {
    this.loaderService.firstLoad.subscribe(async (firstLoadComplete) => {
      if (firstLoadComplete) {
        this.userRoleView = { userView: [], highestAccess: null };
      this.userRoleView.userView = [];
      this.userRoleView = await this.userAuthorizationService.getUserRoleView(
        Number(sessionStorage.getItem('projectId'))
      );
      this.projectId = sessionStorage.getItem('projectId');
      this.selectedUserRoleView = this.userRoleView.highestAccess;
      }
    });
    //TODO :- Need to reload based on highest access
    // this.validateRequestedView();
  }


  public validateRequestedView(): void {
    if (this.selectedViewIndex != this.userRoleView.highestAccess.referenceId) {
      const url = new URL(window.location.href);
      url.searchParams.delete('selectedViewIndex');
      url.searchParams.delete('projectId');
      url.searchParams.set('projectId', this.projectId);
      url.searchParams.set(
        'selectedViewIndex',
        this.userRoleView.highestAccess.referenceId.toString()
      );
      window.location.href = url.toString();
    }
  }

  public getUserData(): void {
    if (this.userFullName != undefined) {
      this.initials = this.userFullName.slice(0, 1);
      this.initials = this.initials + this.userFullName.split(', ')[1][0];
    }
  }

  public toggle(show?: boolean): void {
    this.showUserMenu = show !== undefined ? show : !this.showUserMenu;
  }

  public logOut(): void {
    this.showUserMenu = false;
  }

  public sendMail(): void {
    window.location.href = 'mailto:globaldep@deloitte.com';
  }

  private contains(target: EventTarget): boolean {
    return (
      this.userBtn.nativeElement.contains(target) ||
      (this.userMenu ? this.userMenu.nativeElement.contains(target) : false)
    );
  }
  public navigateToUrl(): void {
    window.location.assign('https://www.deloitte.com/global/en.html');
  }

  public userRoleViewChange(userRole: UserRoleView): void {
    const BackgroundUrl = new URL(environment.depBaseURL);
    switch (userRole.referenceId) {
      case 0:
        BackgroundUrl.pathname += 'Home/Index/';
        break;
      case 24: {
        BackgroundUrl.pathname += 'Admin/GrantAccess';
        BackgroundUrl.searchParams.append(
          'selectedViewIndex',
          userRole.referenceId.toString()
        );
        break;
      }
      case 25: {
        BackgroundUrl.pathname += 'Admin/ClientMaster';
        BackgroundUrl.searchParams.append(
          'selectedViewIndex',
          userRole.referenceId.toString()
        );
        break;
      }
      case 12:
      case 13:
      case 26: {
        BackgroundUrl.pathname += 'Admin/Index';
        BackgroundUrl.searchParams.append(
          'selectedViewIndex',
          userRole.referenceId.toString()
        );
        break;
      }
      case 11:
      case 23: {
        BackgroundUrl.pathname += 'Admin/MFProjectSearch';
        BackgroundUrl.searchParams.append(
          'selectedViewIndex',
          userRole.referenceId.toString()
        );
        break;
      }
      case 30:
      case 31:
      case 32: {
        BackgroundUrl.pathname += 'Admin/PortfolioLeaderProjectSearch';
        BackgroundUrl.searchParams.append(
          'selectedViewIndex',
          userRole.referenceId.toString()
        );
        break;
      }
      default:
        BackgroundUrl.pathname += 'Home/Index/';
    }
    window.location.assign(BackgroundUrl);
  }
}
