import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { DatePipe, HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';



import { CoreModule } from './core/core.module';
import { msalConfig } from './shared/configs/auth-config';
import { ServerErrorInterceptor } from './shared/interceptors/server-error-interceptor';
import { UserPolicyAuthorizationService } from './shared/services/userservice/user-policy-authorization.service';
import { UserPolicyRolesGuard } from './shared/guards/user-policy-roles/user-policy-roles.guard';
import { SharedModule } from './shared/shared.module';
// import { PrintViewComponent } from './pages/print-view/print-view.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { HealthCheckComponent } from './pages/health-check/health-check.component';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { CustomErrorHandler } from './shared/services/custom-error-handler';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    //PrintViewComponent
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [environment.argusAD.protectedResourceScopes],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.argusAD.protectedResourceMapURL, [environment.argusAD.protectedResourceScopes]],
          [environment.argusAD.protectedResourceEndpoint, [environment.argusAD.apiScopes]]
        ])
      }),
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    PDFExportModule

  ],
  providers: [
     {
       provide: HTTP_INTERCEPTORS,
       useClass: MsalInterceptor,
       multi: true
     },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    { provide: ErrorHandler,
      useClass: CustomErrorHandler
     },
      Location, { provide: LocationStrategy, useClass: HashLocationStrategy },
      MsalGuard,
      UserPolicyRolesGuard,
      UserPolicyAuthorizationService,
      DatePipe
  ]
    ,
  bootstrap: [AppComponent
    ,MsalRedirectComponent
  ]
})
export class AppModule { }
