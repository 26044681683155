import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Pass the request to the next handler
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const timestamp = new Date().toISOString();

        // Log the error to the console
        console.error(`Interceptor-[HTTP Error] ${timestamp}`, error);

        // Handle specific status codes
        switch (error.status) {
          case 401:
            this.router.navigate(['/AccessDenied']);
            break;
          case 400:
          case 403:
          case 500:
            this.handleServerError(error);
            break;
          default:
            console.error('Interceptor-[Unhandled HTTP error]:', error.message);
        }

        return throwError(() => new Error(error.message));
      })
    );
  }

  private handleServerError(error: HttpErrorResponse): void {
    if (error?.error?.error_description) {
      console.log("Interceptor-[Bad request]:", error);
      console.error('Interceptor-[Bad Request error description]:', error.error.error_description);
    } else if (error?.error?.errors) {
      const errorMessages = Object.entries(error.error.errors).map(([key, value]) => {
        const message = `${key}: ${value}`;
        console.error(message);
        return message;
      });

      console.log("Interceptor-[ErrorMessages]:", errorMessages);
    } else {
      console.error('Interceptor-[Server error]:', error.message);
    }
  }
}


